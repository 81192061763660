<template>
  <div class="container">
    <div class="login-container">
      <div class="login-form">
        <div class="title">
          用户登录
          <a class="register-text">注册</a>
        </div>
        <el-form ref="loginForm">
          <el-form-item prop="account">
            <el-input prefix-icon="el-icon-user" v-model="form.account" placeholder="账号" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input type="password" prefix-icon="el-icon-lock" v-model="form.password" placeholder="密码"
                      autocomplete="off"></el-input>
          </el-form-item>

          <div class="forget">
            <a class="forget-text">忘记密码？</a>
          </div>

          <div style="text-align: center">
            <el-button type="primary" @click="onSubmit" style="width: 100%;">登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'login',
    data () {
      return {
        form: {
          account: 'whc',
          password: '123456',
          captcha: '',
        },
        captchaImage: ''
      }
    },
    created () {
    },
    methods: {
      getCaptcha () {
        document.getElementById('captcha').src = '/admin/captcha?v=' + Math.random()
      },
      onSubmit () {
        this.$http
          .post('/index/login', this.form)
          .then(res => {
            const token= res.data || ''
            localStorage.setItem('token', token)
            this.$router.replace('/')
          })
          .catch(err => {
            this.$message.error(err.message)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    background-size: cover;
    background-image: url("../assets/images/banner.png");
    min-height: 100%;
    width: 100%;
    background-color: #2d3a4b;

    .login-container {
      position: relative;
      width: 1200px;
      margin: 0 auto;
    }

    .login-form {
      color: #666666;
      width: 300px;
      position: absolute;
      margin-top: 20%;
      right: 9%;
      padding: 25px 40px 35px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 5px 0 #666666;

      ::v-deep .el-input__icon {
        font-size: 20px;
      }
    }

    .title {
      line-height: 60px;
      font-size: 21px;

      .register-text {
        text-decoration: none;
        color: #0085f0;
        cursor: pointer;
        position: absolute;
        right: 42px;
        top: 34px;
        font-size: 15px;
      }
    }

    .forget-text {
      text-decoration: none;
      text-align: right;
      margin-bottom: 15px;
      color: #999;
      width: 100%;
      float: right;
      display: inline-block;
    }
    a.forget-text:hover, .register-text:hover {
      text-decoration: underline;
      color: #0085f0;
      cursor: pointer;
    }
  }
</style>
